<template>
	<div>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_1ddfdcf5b21699d5')" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="1200px">
		<div style="padding: 10px;">
			<el-form ref="form" inline :rules="formRules" :model="form" label-width="150px" v-loading="loading">
			
				<!-- <el-form-item :label="$t('i18nn_76dc341edac65885')" prop="bizType">
					<el-select filterable clearable v-model="form.bizType" :placeholder="$t('2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="item in selectOption.wh_biz_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
			
				<el-form-item :label="$t('i18nn_c5400c966a9be32d')" prop="opModel">
					<el-select filterable clearable v-model="form.opModel" :placeholder="$t('2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="item in selectOption.wh_op_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item> -->
				<!-- "whNo": null, //"仓库编号-唯一不可修改",
				"whName": null, //$t('i18nn_5841c2904805147a'),
				"whNameEn": null, //$t('i18nn_af76f74cc6174a6d'),
				"locationType": null, //$t('i18nn_b123d7a0a2181bb2'),
				"country": null, //$t('b166e4e8fe9513fa'),
				"postalCode": null, //$t('c4913ab43009b365'),
				"state": null, //"州/省",
				"city": null, //$t('e05b5d049b64b040'),
				"address": null, //$t('i18nn_8758fd50c87d6c9c'),
				"name": null, //$t('i18nn_5f24dcf49cbc6b69'),
				"company": null, //$t('i18nn_f7db73b2f3f1f0bf'),
				"phoneNumber": null, //$t('c2d9cc88561f8cdc') 
				"email":: null, //$t('i18nn_8b9b30a5bc0eab3a'),
				"area":: null, //$t('i18nn_c77a882ff6fa16b9'),
				"partnerType":: null, //$t('i18nn_0cc0c5206402c130')-->
				<el-form-item :label="$t('5acbec83efb27445')" prop="whNo">
					<el-input type="text" clearable v-model="form.whNo" :disabled="!!form.id"></el-input>
				</el-form-item>
			
				<el-form-item :label="$t('i18nn_5841c2904805147a')" prop="whName">
					<el-input type="text" clearable v-model="form.whName"></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_af76f74cc6174a6d')" prop="whNameEn">
					<el-input type="text" clearable v-model="form.whNameEn"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_b123d7a0a2181bb2')" required>
					<!-- <el-input type="text" clearable v-model="form.locationType"></el-input> -->
					<el-select filterable clearable v-model="form.locationType" :placeholder="$t('2ad108ab2c560530')"
						>
						<el-option v-for="item in selectOption.wh_location_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('b166e4e8fe9513fa')" prop="" required>
					<el-input type="text" clearable v-model="form.country"></el-input>
				</el-form-item>
				<el-form-item :label="$t('c4913ab43009b365')" prop="" required>
					<el-input type="text" clearable v-model="form.postalCode"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_7cab79716236ebb0')+'/'+$t('i18nn_f9514183570a10fe')" prop="" required>
					<el-input type="text" clearable v-model="form.state"></el-input>
				</el-form-item>
				<el-form-item :label="$t('e05b5d049b64b040')" prop="" required>
					<el-input type="text" clearable v-model="form.city"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" required>
					<el-input type="text" clearable v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_5f24dcf49cbc6b69')" prop="" required>
					<el-input type="text" clearable v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_f7db73b2f3f1f0bf')" prop="" required>
					<el-input type="text" clearable v-model="form.company"></el-input>
				</el-form-item>
				<el-form-item :label="$t('c2d9cc88561f8cdc')" prop="">
					<el-input type="text" clearable v-model="form.phoneNumber"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_b2d4c7fffe79258e')" prop="">
					<el-input type="text" clearable v-model="form.email"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_c77a882ff6fa16b9')+'('+$t('i18nn_bd16e84498ab145f')+'sqft)'" prop="" required>
					<el-input-number v-model="form.area"></el-input-number>
				</el-form-item>
				<el-form-item :label="$t('i18nn_0cc0c5206402c130')" prop="" required>
					<!-- <el-input type="text" clearable v-model="form.partnerType"></el-input> -->
					<el-select filterable clearable v-model="form.partnerType" :placeholder="$t('2ad108ab2c560530')"
						>
						<el-option v-for="item in selectOption.wh_partner_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				
				
				<el-form-item :label="$t('i18nn_dbcffdf31e86d2d8')" prop="" required>
					<el-input-number v-model="form.sortNo"></el-input-number>
				</el-form-item>
			
				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
						 :maxlength="1000" show-word-limit></el-input>
				</el-form-item>
				
				<div>
					<el-form-item :label="$t('i18nn_9426df1162e7b5fa')">
						<!-- <el-form-item :label="$t('Storage.skuInfo.SKU_pictures')" prop=""> -->
						<!-- {{form.headerImg}}s -->
							<HyUpLoadImg ref="hyUpLoadImg1" :imgId="UserInfo.id" :imgwidth="300" :imgheight="200"
								:folder="'storage/Warehouse/'" :imgKey="'WhDetInfo'" :bucket="'hytx-oss-wh'" :maxSizeMB="5"
								:size="'small'" :hasRemove="true" 
								v-on:changeImgUrl="hyUpLoadImg1" 
								@removeImg="removeImgUrl">
								<!-- <div slot="imgDesc">
									<div style="padding:10px; width: 200px;">
										
									</div>
								</div> -->
							</HyUpLoadImg>
						<!-- </el-form-item> -->
					</el-form-item>
				</div>
				
				<div>
					<el-form-item :label="$t('i18nn_526f935030526185')">
						<el-button type="primary" size="small" icon="el-icon-paperclip"
							@click="openAddFile()">{{$t('i18nn_06f0c71344e7183c')}}</el-button>
						<ul>
							<li v-for="(item2,index2) in form.imgList" :key="index2">
								<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
									:src="item2.imgUrl" :preview-src-list="form.imgList.map(itemPre=> { return itemPre.imgUrl})">
									<div slot="error" class="image-slot">
										<i class="el-icon-document"></i>
									</div>
								</el-image>
								<a :href="item2.imgUrl" :title="item2.imgUrl" target="_blank">{{ item2.fileName }}</a>
						
								<el-button @click="delFile($event,index2)" type="text" size="mini" icon="el-icon-delete"
									style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
							</li>
						</ul>
					</el-form-item>
				</div>
				<div>
					<el-form-item :label="$t('i18nn_ab6ea90b9164b20a')">
						<HyQuillEditor ref="myTextEditor" :value="form.whContent"
							@editorChange="onEditorChange" class=""></HyQuillEditor>
					</el-form-item>
				</div>
				
			</el-form>
		</div>

		<div class="drawer-footer">
			<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button> -->
		</div>
	</el-drawer>
	
	<!-- 附件 -->
	<dialogFileUpload ref="dialogFileUpload" :accept="'image/png,image/x-png,image/jpg,image/jpeg,image/gif'" :folder="'storage/Warehouse/'" :fileKey="'WarehouseImg'"
		:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
	
		
	</div>
</template>
<script>
	import {
		deepClone,
		clearObjectVal
	} from '@/utils/utils.js';
	
	import {
		getDicData
	} from '@/axios/common.js';
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	// import enterpriseSelFuzzy from '@/components/WarehouseCenter2/components/enterpriseSelFuzzy.vue';
	import HyUpLoadImg from '@/components/Common/HyUpLoadImg.vue';
	// import EnterperiseWhPriceTem from '@/components/WarehouseCenter2/Deal/EnterperiseWhPriceTem.vue';
	import dialogFileUpload from '@/components/WarehouseCenter2/components/dialogFileUpload.vue';
	import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	
	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row:{}
		},
		components: {
			dialogFileUpload,
			HyQuillEditor,
			HyUpLoadImg
			// AccountUserList,
			// enterpriseSelFuzzy,
			// whNoAllSelect,
			// EnterperiseWhPriceTem
			// dialogWarehouse
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,
				activeName: 'first',
				
				UserInfo: this.$store.getters.getUserInfo,
				
				FileUploadOpenTime: '',
				
				// loadingUpload: false,
				loading: false,
				form: {
					"whNo": null, //"仓库编号-唯一不可修改",
					"whName": null, //this.$t('i18nn_5841c2904805147a'),
					"whNameEn": null, //this.$t('i18nn_af76f74cc6174a6d'),
					"locationType": null, //this.$t('i18nn_b123d7a0a2181bb2'),
					"country": 'US', //this.$t('b166e4e8fe9513fa'),
					"postalCode": null, //this.$t('c4913ab43009b365'),
					"state": null, //"州/省",
					"city": null, //this.$t('e05b5d049b64b040'),
					"address": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"name": null, //this.$t('i18nn_5f24dcf49cbc6b69'),
					"company": null, //this.$t('i18nn_f7db73b2f3f1f0bf'),
					"phoneNumber": null, //this.$t('c2d9cc88561f8cdc')
					"email": null, //this.$t('i18nn_8b9b30a5bc0eab3a'),
					"area":null, //this.$t('i18nn_c77a882ff6fa16b9'),
					"partnerType": null, //this.$t('i18nn_0cc0c5206402c130')
					"sortNo":null,
					imgList:[],
					whContent:""
					
				},
				
				formRules: {
					whNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					whName: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					whNameEn: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					feeType: [{
						required: true,
						message: this.$t('FormMsg.Please_select'),
						trigger: 'change'
					}]
				},
				
				selectOption: {
					// wh_no: []
					wh_partner_type: [],
					wh_location_type: [],
				},
				
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_partner_type', 'wh_location_type'],
				(data)=>{
					this.selectOption.wh_partner_type = data['wh_partner_type'];
					this.selectOption.wh_location_type = data['wh_location_type'];
			});
		},
		methods: {
			initData() {
				// let form = Object.assign({}, formParm);
				// console.log('form', form);
				// // 重置
				this.resetForm('form');
				// if (null === formParm) {
				//新增
				// this.form.enterpriseId = '';
				// this.$nextTick(() => {
				// 	this.$refs.enterpriseSelFuzzy.clearData();
				// 	this.$refs.whNoAllSelect2.init('');
				// });
				// // this.form.userName = '';
				// // this.form.whNoArr = [];
				// // this.form.whNos = [];
				// // this.form.whNoArr = [];
				// this.form.id = null;
				// this.form.whId = null;
				// this.form.whNo = null;
				// this.form.priceObj = {};
				// this.form.remark = '';

				// this.dialogFormStatus = 0;
				
				console.log('this.row',this.row);
				
				if(!!this.row){
					this.form = deepClone(this.row);
					this.getDetData(this.row.contextKey);
					this.$nextTick(()=>{
						if (this.$refs.hyUpLoadImg1) {
							console.log('this.$refs.hyUpLoadImg1',this.$refs.hyUpLoadImg1);
							
							this.$refs.hyUpLoadImg1.initUrl(this.form.headerImg);
						}
					})
					
				} else {
					// this.getTemData();
				}
				
			},
			// changCus2(data) {
			// 	console.log('changCus', data);

			// 	this.form.enterpriseId = data.id;
			// 	// this.filterData.userName = data.companyName;
			// 	// this.initData();
			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo2', data);
			// 	this.form.whId = data.id;
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userName = data.companyName;
			// 	// this.initData();
			// },
			// getTemData() {
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.HyEntWhPriceTmp, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (data.data) {
			// 				this.form.priceObj = data.data;
			// 			}

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 		});
			// },
			getDetData(keyId){
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhNoticeMoreText+"?keyId="+keyId, {})
					.then(({ data }) => {
						// console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						if(data.data){
							this.form.whContent = data.data;
							// try{
							// 	this.form.priceObj = JSON.parse(this.form.priceJson);
							// }catch(e){
							// 	//TODO handle the exception
							// 	console.log(e);
							// }
							// this.$nextTick(() => {
							// 	this.$refs.enterpriseSelFuzzy.init(this.form.enterpriseId);
							// 	this.$refs.whNoAllSelect2.init(this.form.whNo);
							// });
						}
					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading = false;
					});
			},
			
			hyUpLoadImg1(childValue) {
				// childValue就是子组件传过来的值
				this.form.headerImg = childValue;
			},
			removeImgUrl(){
				this.form.headerImg = "";
				this.$nextTick(()=>{
					if (this.$refs.hyUpLoadImg1) {
						this.$refs.hyUpLoadImg1.initUrl("");
					}
				})
				
			},
			
			//添加附件
			openAddFile() {
				this.FileUploadOpenTime = new Date().getTime();
			},
			
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.imgList.splice(index, 1);
			},
			
			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						// url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
						imgUrl: item.filePath,
					}
				});
				// // this.form.qaProgress.attachments = fileList;
				// this.form.qaProgress.attachments = this.form.qaProgress.attachments.concat(fileList);
				// this.$forceUpdate();
				if(!this.form.imgList){
					this.form.imgList = [];
				}
				// let index = this.TransportOpenIndex;
				// let blockData = this.form.recordList[index];
				let imgList = this.form.imgList.concat(fileList);
				// blockData.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
				// this.$set(this.form.recordList, blockData, index);
				this.form.imgList = imgList;
				this.$forceUpdate();
			
			},
			
			// 富文本
			onEditorChange(html) {
				// this.form.contentStr = html;
				this.form.whContent = html;
			},
			
			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						// if (0 === this.dialogFormStatus) {
						// 	this.postData(this.$urlConfig.WhWarehouseAdd, formData,'',()=>{
								
						// 	});
						// } else {
							this.postData(this.$urlConfig.WhWarehouseAdd, formData,'',()=>{
								this.dialogFile = false;
								this.$emit('success');
							});
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form = clearObjectVal(this.form);
					this.form.country = 'US';
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				this.$nextTick(()=>{
					if (this.$refs.hyUpLoadImg1) {
						console.log('resetForm');
						this.$refs.hyUpLoadImg1.initUrl('');
					}
				})
				
			},
			
			//提交信息
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// // this.getPageData();
						// // this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// //   type: 'success',
						// //   //confirmButtonText: this.$t('204ffab8a6e01870'),
						// // });
						// // this.$message.success(this.$t('tips.submitSuccess'));
						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						// 	// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	// 	type: 'success'
						// 	// });
						// 	this.$message.success(this.$t('tips.submitSuccess'));
						// }
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},
			
			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_partner_type', 'wh_location_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_partner_type = data.data['wh_partner_type'];
			// 				this.selectOption.wh_location_type = data.data['wh_location_type'];
			
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>