<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_395edce59b8bf304')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button v-if="!isSel" @click="openBindCusListAction()" type="primary" size="small"
						icon="el-icon-notebook-2">{{$t('i18nn_bba12068e25729f4')}}</el-button> -->
					<!-- <span class="" v-if="!isSel"> -->
					<el-button v-if="!isSel" type="success" @click="openDioalog(null, $t('c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
					<!-- </span> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{$t('i18nn_b123d7a0a2181bb2')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_location_type"
						:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_location_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li> -->
				<!-- <li>
					<span>{{$t('i18nn_0cc0c5206402c130')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_partner_type"
						:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_partner_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li> -->
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable
						@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
						style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- "whNo": null, //"仓库编号-唯一不可修改",
				"whName": null, //$t('i18nn_5841c2904805147a'),
				"whNameEn": null, //$t('i18nn_af76f74cc6174a6d'),
				"locationType": null, //$t('i18nn_b123d7a0a2181bb2'),
				"country": null, //$t('b166e4e8fe9513fa'),
				"postalCode": null, //$t('c4913ab43009b365'),
				"state": null, //"州/省",
				"city": null, //$t('e05b5d049b64b040'),
				"address": null, //$t('i18nn_8758fd50c87d6c9c'),
				"name": null, //$t('i18nn_5f24dcf49cbc6b69'),
				"company": null, //$t('i18nn_f7db73b2f3f1f0bf'),
				"phoneNumber": null, //$t('c2d9cc88561f8cdc') 
				"email":: null, //$t('i18nn_8b9b30a5bc0eab3a'),
				"area":: null, //$t('i18nn_c77a882ff6fa16b9'),
				"partnerType":: null, //$t('i18nn_0cc0c5206402c130')-->
				<el-table-column prop="state" :label="$t('6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.status" type="success">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else-if="'20'===scope.row.status" type="warning">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else type="info">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				
				<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
				<el-table-column prop="whName" :label="$t('i18nn_5841c2904805147a')"></el-table-column>
				<el-table-column prop="whNameEn" :label="$t('i18nn_af76f74cc6174a6d')"></el-table-column>
				<el-table-column prop="locationTypeName" :label="$t('i18nn_b123d7a0a2181bb2')"></el-table-column>
				
				<el-table-column prop="address" :label="$t('i18nn_9461d15f7ba8ad99')">
					<template slot-scope="scope">
						<div>
							
							<el-popover placement="top" trigger="hover" >
								<div class="pre-text">
									{{scope.row.address}},{{scope.row.city}},{{scope.row.state}},{{scope.row.postalCode}},{{scope.row.country}}
								</div>
								<span class="over_ellipsis" slot="reference">
									{{scope.row.address}},{{scope.row.city}},{{scope.row.state}},{{scope.row.postalCode}},{{scope.row.country}}
								</span>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="country" :label="$t('b166e4e8fe9513fa')"></el-table-column>
				<el-table-column prop="postalCode" :label="$t('c4913ab43009b365')"></el-table-column>
				<el-table-column prop="state" :label="$t('i18nn_7cab79716236ebb0')+'/'+$t('i18nn_f9514183570a10fe')"></el-table-column>
				<el-table-column prop="city" :label="$t('e05b5d049b64b040')"></el-table-column>
				<el-table-column prop="address" :label="$t('i18nn_8758fd50c87d6c9c')"></el-table-column>
				<el-table-column prop="name" :label="$t('i18nn_5f24dcf49cbc6b69')"></el-table-column>
				<el-table-column prop="company" :label="$t('i18nn_f7db73b2f3f1f0bf')"></el-table-column>
				<el-table-column prop="phoneNumber" :label="$t('c2d9cc88561f8cdc')"></el-table-column>
				<el-table-column prop="email" :label="$t('i18nn_b2d4c7fffe79258e')"></el-table-column>
				<el-table-column prop="area" :label="$t('i18nn_c77a882ff6fa16b9')+'('+$t('i18nn_bd16e84498ab145f')+'sqft)'"></el-table-column>
				<el-table-column prop="partnerTypeName" :label="$t('i18nn_0cc0c5206402c130')"></el-table-column>
				<el-table-column prop="sortNo" :label="$t('i18nn_dbcffdf31e86d2d8')"></el-table-column>
				
				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="headerImg" :label="$t('i18nn_9426df1162e7b5fa')">
					<template slot-scope="scope">
						<div>
							<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.headerImg"
								:preview-src-list="tableData.map(itemPre=> { return itemPre.headerImg})">
								<div slot="error" class="image-slot" style="font-size: 40px;"><i
										class="el-icon-picture-outline"></i></div>
							</el-image>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="260px" fixed="right">
					<template slot-scope="scope">
						<div v-if="isSel">
							<el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini"
								icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button>
						</div>
						<div v-else>
							<div style="margin-bottom: 5px;">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
									
								<el-button v-if="'10'==scope.row.status" @click="closeAction($event, scope.row)" type="warning" size="mini"
										icon="el-icon-close">{{$t('4e9fc68608c60999')}}</el-button>
										
								<el-button v-else @click="openAction($event, scope.row)" type="success" size="mini"
										icon="el-icon-check">{{$t('70fdf36bdc2b0f58')}}</el-button>
							</div>
							
							<div style="margin-bottom: 5px;">
								<el-button @click="bindCusAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-connection">{{$t('i18nn_ab505f0b8db48c6c')}}</el-button>
								<!-- <el-button @click="bindAdminAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-connection">绑定管理员</el-button> -->
							</div>

						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" :title="dialogFormMsg" append-to-body
			:visible.sync="dialogFormVisible" width="1200px">
			<el-form ref="form" inline :rules="formRules" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-form-item :label="$t('5acbec83efb27445')" prop="whNo">
					<el-input type="text" clearable v-model="form.whNo" :disabled="!!form.id"></el-input>
				</el-form-item>

				<el-form-item :label="$t('i18nn_5841c2904805147a')" prop="whName">
					<el-input type="text" clearable v-model="form.whName"></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_af76f74cc6174a6d')" prop="whNameEn">
					<el-input type="text" clearable v-model="form.whNameEn"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_b123d7a0a2181bb2')" required>
					<el-select filterable clearable v-model="form.locationType" :placeholder="$t('2ad108ab2c560530')"
						>
						<el-option v-for="item in selectOption.wh_location_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('b166e4e8fe9513fa')" prop="" required>
					<el-input type="text" clearable v-model="form.country"></el-input>
				</el-form-item>
				<el-form-item :label="$t('c4913ab43009b365')" prop="" required>
					<el-input type="text" clearable v-model="form.postalCode"></el-input>
				</el-form-item>
				<el-form-item :label="'州/省'" prop="" required>
					<el-input type="text" clearable v-model="form.state"></el-input>
				</el-form-item>
				<el-form-item :label="$t('e05b5d049b64b040')" prop="" required>
					<el-input type="text" clearable v-model="form.city"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" required>
					<el-input type="text" clearable v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_5f24dcf49cbc6b69')" prop="" required>
					<el-input type="text" clearable v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_f7db73b2f3f1f0bf')" prop="" required>
					<el-input type="text" clearable v-model="form.company"></el-input>
				</el-form-item>
				<el-form-item :label="$t('c2d9cc88561f8cdc')" prop="">
					<el-input type="text" clearable v-model="form.phoneNumber"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_b2d4c7fffe79258e')" prop="">
					<el-input type="text" clearable v-model="form.email"></el-input>
				</el-form-item>
				<el-form-item :label="'面积(平方英尺sqft)'" prop="" required>
					<el-input type="text" clearable v-model="form.area"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_0cc0c5206402c130')" prop="" required>
					<el-select filterable clearable v-model="form.partnerType" :placeholder="$t('2ad108ab2c560530')"
						>
						<el-option v-for="item in selectOption.wh_partner_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				
				
				<el-form-item :label="$t('i18nn_dbcffdf31e86d2d8')" prop="" required>
					<el-input-number v-model="form.sortNo"></el-input-number>
				</el-form-item>
				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"
						maxlength="" show-word-limit></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog> -->
		<!-- 客户绑定仓库列表 -->
		<!-- <CustomerBindWhDialog :openTime="CustomerBindWhDialogOpenTime" @success="AddSuccessBack"></CustomerBindWhDialog> -->
		
		<!-- 绑定客户 -->
		<dialogWhBindCus :openTime="whBindCusAddOpenTime" :row="whBindCusRow" @success="AddSuccessBack"></dialogWhBindCus>
		
		<!--新增-->
		<WarehouseManaAdd :openTime="whAddOpenTime" :row="whRow"  @success="AddSuccessBack"></WarehouseManaAdd>
		
	</div>
</template>
<script>
	// import {
	// 	clearObjectVal
	// } from '@/utils/utils.js';
	
	import WarehouseManaAdd from '@/components/WarehouseCenter2/SysConfig/WarehouseManaAdd.vue';
	
	import dialogWhBindCus from '@/components/WarehouseCenter2/SysConfig/dialogWhBindCus.vue';
	
	// import CustomerBindWhDialog from '@/components/WarehouseCenter2/AccountBind/CustomerBindWhDialog.vue';
	
	export default {
		components: {
			// CustomerBindWhDialog,
			dialogWhBindCus,
			WarehouseManaAdd
		},
		props: {
			// mobile:"",
			isSel: {
				default: function() {
					return false
				},
				type: Boolean
			},
		},
		data() {
			return {
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				// dialogSelVisible: false,
				// CustomerBindWhDialogOpenTime:false,
				
				whBindCusAddOpenTime:'',
				whBindCusRow:{},
				
				whAddOpenTime:'',
				whRow:{},
				// CateValue: [],
				loading: false,
				// form: {
				// 	"whNo": null, //"仓库编号-唯一不可修改",
				// 	"whName": null, //this.$t('i18nn_5841c2904805147a'),
				// 	"whNameEn": null, //this.$t('i18nn_af76f74cc6174a6d'),
				// 	"locationType": null, //this.$t('i18nn_b123d7a0a2181bb2'),
				// 	"country": null, //this.$t('b166e4e8fe9513fa'),
				// 	"postalCode": null, //this.$t('c4913ab43009b365'),
				// 	"state": null, //"州/省",
				// 	"city": null, //this.$t('e05b5d049b64b040'),
				// 	"address": null, //this.$t('i18nn_8758fd50c87d6c9c'),
				// 	"name": null, //this.$t('i18nn_5f24dcf49cbc6b69'),
				// 	"company": null, //this.$t('i18nn_f7db73b2f3f1f0bf'),
				// 	"phoneNumber": null, //this.$t('c2d9cc88561f8cdc')
				// 	"email": null, //this.$t('i18nn_8b9b30a5bc0eab3a'),
				// 	"area":null, //this.$t('i18nn_c77a882ff6fa16b9'),
				// 	"partnerType": null, //this.$t('i18nn_0cc0c5206402c130')
				// 	"sortNo":null,
					
				// },

				// formRules: {
				// 	whNo: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],
				// 	whName: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],
				// 	whNameEn: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],
				// 	feeType: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_select'),
				// 		trigger: 'change'
				// 	}]
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: []
					// wh_partner_type: [],
					// wh_location_type: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'sort_no', //排序字段
					sortAsc: 'N', //desc降序，asc升序
					// userName: '',
					wh_location_type: '',
					wh_partner_type: '',
					keyword: ''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
				// this.getWhNoListData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},

			//选择仓库
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },

			//打开新增编辑，弹窗
			openDioalog(formParm, msg) {
				this.whRow = formParm;
				this.whAddOpenTime = new Date().getTime();
				
				// console.log(formParm);
				// this.dialogFormVisible = true;
				// this.dialogFormMsg = msg;
				// let form = Object.assign({}, formParm);
				// console.log('form', form);

				// // // 重置
				// this.resetForm('form');
				// if (null === formParm) {
				// 	//新增
				// 	this.dialogFormStatus = 0;
				// } else {
				// 	// 	//修改
				// 	this.dialogFormStatus = 1;
				// 	this.form = form;
				// }
			},
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				this.openDioalog(row, this.$t('FormMsg.Edit'));
			},
			AddSuccessBack(){
				this.initData();
			},
			
			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhWarehouseDel + '/' + row.id, {}, 'delete');
					})
					.catch(() => {});
			},
			//开启
			openAction(event, row) {
				this.postData(this.$urlConfig.WhWarehouseOpen + '/' + row.id, {}, '');
			},
			//关闭
			closeAction(event, row) {
				this.postData(this.$urlConfig.WhWarehouseClose + '/' + row.id, {}, '');
			},
			
			//绑定客户
			bindCusAction(event, row){
				this.whBindCusAddOpenTime = new Date().getTime();
				this.whBindCusRow = row;
			},
			
			//绑定客户列表
			// openBindCusListAction(){
			// 	this.CustomerBindWhDialogOpenTime = new Date().getTime();
			// },
			//绑定管理员
			// bindAdminAction(event, row){
				
			// },

			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);
			// 			//浅拷贝、对象属性的合并
			// 			if (0 === this.dialogFormStatus) {
			// 				this.postData(this.$urlConfig.WhWarehouseAdd, formData);
			// 			} else {
			// 				this.postData(this.$urlConfig.WhWarehouseAdd, formData);
			// 			}
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			// //重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 		this.form = clearObjectVal(this.form);
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}
			// },

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.getPageData();
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhWarehousePageList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						// states: this.filterData.status ? this.filterData.status : null,
						// plNo: this.filterData.plNo ? this.filterData.plNo : null,
						// cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
						// userId: this.cusUserId,
						// wh_biz_type:'',
						// wh_op_type:'',
						// locationType: this.filterData.wh_location_type ? this.filterData.wh_location_type : null,
						// partnerType: this.filterData.wh_partner_type ? this.filterData.wh_partner_type : null,
						// opModel: this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//选择数据后回调
			selRow(event, row) {
				event.stopPropagation();
				this.$emit('selectRow', row);
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_partner_type', 'wh_location_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_partner_type = data.data['wh_partner_type'];
			// 				this.selectOption.wh_location_type = data.data['wh_location_type'];

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);
</style>
