<template>
	<el-select
		v-model="userId"
		:size="size"
		clearable
		filterable
		remote
		automatic-dropdown
		style="min-width: 200px;"
		default-first-option
		:placeholder="$t('i18nn_1af050f1d11dee5b')"
		:remote-method="remoteMethod"
		@change="changeData"
		:loading="loading"
	>
		<el-option v-for="(item, index) in cusList" :key="index" :label="item.companyName" :value="item.userId">
			<div class="sel_option_s1" style="">
				<span class="sel_option_name">
					<span v-if="!!item.skuPrefix">
						<span>{{item.skuPrefix}}</span>
						<span class="sel_option_name_sub">
							({{ item.companyName }})
							<el-tag size="mini" v-if="'1'==item.isSub">
								子账户
							</el-tag>
							<el-tag size="mini" type="info" plain>
								{{item.account}}
							</el-tag>
						</span>
					</span>
					<span v-else>
						{{ item.companyName }}
						<el-tag size="mini" v-if="'1'==item.isSub">
							子账户
						</el-tag>
						<el-tag size="mini" type="info" plain>
							{{item.account}}
						</el-tag>
					</span>
				</span>
				<span class="sel_option_code" style="">
					<span>{{ item.cusNo }}</span>
				</span>
			</div>
		</el-option>
	</el-select>
</template>
<script>
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	props: {
		size:{
		  default: function() {
		    return 'small'
		  },
		  type: String
		},
		// mobile:"",
		// clearable: {
		//   default: function() {
		//     return true;
		//   },
		//   type: Boolean
		// },
		// userId: {
		//   default: function() {
		//     return '';
		//   },
		//   type: String
		// },
		// fileList: {
		//   default: function() {
		//     return [];
		//   },
		//   type: Array
		// }
	},
	components: {},
	data() {
		return {
			// dialogFile: false,
			// loadingUpload: false,

			userId: '',
			loading: false,
			cusList: []
			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,

			// saveErr: false,

			// listUrl: [],

			// tableData: []
		};
	},
	// watch: {
	//   openTime: function(newVal, oldVal) {
	//     console.log('openTime');
	//     this.dialogFile = true;
	//     this.initData();
	//   }
	// },
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		this.getCusListData('');
	},
	methods: {
		init(val){
			if(val){
				this.userId = val;
			} else {
				this.userId = '';
				this.getCusListData('');
			}
		},
		changeData(data){
			console.log("changeData",data);
			let emit_data = {};
			if(data){
				emit_data = this.cusList.find(item=>{
					return item.userId == data;
				})
			}
			this.$emit('changeData',emit_data);
			//清空,重新搜索默认数据
			if(!data){
				this.getCusListData('');
			}
		},
    clearData(){
      this.userId = '';
			this.getCusListData('');
    },
		//客户模糊搜索
		remoteMethod(query) {
			// if (query !== '') {
				this.getCusListData(query);
			// } else {
			// 	this.cusList = [];
			// }
		},
		//客户模糊请求
		getCusListData(key) {
			this.loading = true;
			this.$http
				.get(this.$urlConfig.WhCustomerAllList+"?keyword="+key, {})
				.then(({ data }) => {
					console.log('名称搜索，请求成功');
					console.log(data);
					
					this.loading = false;
					if (200 == data.code) {
						this.cusList = data.rows;
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('i18nn_e12ec0fbad09bac1'));
					}
				})
				.catch(error => {
					console.log(error);
					console.log('搜索，请求失败');
					this.$message.error(this.$t('i18nn_8d5c0114856bda15'));
					this.loading = false;
				});
		}
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
